import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { Layout } from '@abyss/web/ui/Layout';
import { Grid } from '@abyss/web/ui/Grid';
import { Text } from '@abyss/web/ui/Text';
import { DetailViewTable } from './DetailViewTable';

export const DetailView = () => {
  return (
    <React.Fragment>
      <Router.MetaTags title="Detail View" />
      <Layout.Stack
        css={{
          paddingTop: '40px',
        }}
        alignLayout="center"
      >
        <Layout.Stack
          css={{
            alignItems: 'flex-start',
            maxWidth: 'var(--abyss-sizes-xl)',
          }}
        >
          <Grid
            css={{
              width: '100%',
              paddingBottom: '10px',
            }}
          >
            <Grid.Col span="100%">
              <Text
                css={{
                  color: '$primary1',
                  h1: {
                    margin: '0',
                  },
                }}
              >
                <h1>Prime Detail View</h1>
              </Text>
            </Grid.Col>
            <Grid.Col span="33.33%">
              <Text>
                <strong>Customer Name: </strong>Anand Vai
              </Text>
            </Grid.Col>
            <Grid.Col span="33.33%">
              <Text>
                <strong>Processing Date: </strong>11/21/2024
              </Text>
            </Grid.Col>
            <Grid.Col span="33.33%">
              <Text>
                <strong>Member name: </strong>Anand Vai
              </Text>
            </Grid.Col>
          </Grid>
          <DetailViewTable />
        </Layout.Stack>
      </Layout.Stack>
    </React.Fragment>
  );
};
