import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';

import React from 'react';

const detaiViewData = [
  {
    fieldName: 'Policy Number',
    dataPriorToProcessing: '',
    incomingCustomerData: '045xyx',
    dataAfterProcessing: 'lkjsdf',
  },
  {
    fieldName: 'Policy Number',
    dataPriorToProcessing: '',
    incomingCustomerData: '045xyx',
    dataAfterProcessing: 'lkjsdf',
  },
  {
    fieldName: 'Policy Number',
    dataPriorToProcessing: '',
    incomingCustomerData: '045xyx',
    dataAfterProcessing: 'lkjsdf',
  },
  {
    fieldName: 'Policy Number',
    dataPriorToProcessing: '',
    incomingCustomerData: '045xyx',
    dataAfterProcessing: 'lkjsdf',
  },
  {
    fieldName: 'Policy Number',
    dataPriorToProcessing: '',
    incomingCustomerData: '045xyx',
    dataAfterProcessing: 'lkjsdf',
  },
];

export const DetailViewTable = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Field Name',
        accessor: 'fieldName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        width: 400,
      },
      {
        Header: 'Data Prior To Processing',
        accessor: 'dataPriorToProcessing',
        width: 250,
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Field Name',
        accessor: 'incomingCustomerData',
        width: 250,
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Data After Processing',
        accessor: 'dataAfterProcessing',
        width: 250,
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
    ],
    []
  );

  const dataTableProps = useDataTable({
    initialData: detaiViewData,
    initialColumns: columns,
    showPagination: false,
    showTableSettings: false,
    showColumnSort: false,
  });

  return (
    <React.Fragment>
      <DataTable hideTitleHeader tableState={dataTableProps} />
    </React.Fragment>
  );
};
