import { getCachedUserDetails } from '@src/utils';
import { apiService } from './ServiceApi';
// Define a service using a base URL and expected endpoints

export const parentService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getAllParentFiles: builder.query({
      query: (pData) => {
        const { isBroker } = getCachedUserDetails();
        return {
          method: 'POST',
          url: 'newtabPrime',
          params: {
            searchFor: pData.searchFor,
            searchType: pData.searchType,
            fileProcessingStatus: pData.fileProcessingStatus,
            portalType: 1,
            isBroker,
          },
        };
      },
    }),
    getchildFiles: builder.query({
      query: (pData) => {
        const { isBroker } = getCachedUserDetails();
        return {
          method: 'POST',
          url: `newtabPrime/getChild/${pData.transFileId}`,
          params: {
            searchFor: pData.searchFor,
            searchType: pData.searchType,
            fileProcessingStatus: pData.fileProcessingStatus,
            portalType: 1,
            isBroker,
          },
        };
      },
    }),
    getFileDetails: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getFileDetails`,
        params: {
          fileId: transFileId,
          txnSetId: '0',
        },
      }),
    }),
    getAddTrans: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getAddTrans`,
        params: {
          fileId: transFileId,
          txnSetId: '0',
        },
      }),
    }),
    getChangeTrans: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getChangeTrans`,
        params: {
          fileId: transFileId,
          txnSetId: '0',
        },
      }),
    }),
    getnochangetrans: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/nochangetrans`,
        params: {
          fileId: transFileId,
          txnSetId: '0',
        },
      }),
    }),

    getInternalERWSummary: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getInternalERWSummary`,
        params: {
          fileId: transFileId,
          txnSetId: '0',
          m_errType: ' ',
        },
      }),
    }),

    getExternalERWSummary: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getExternalERWSummary`,
        params: {
          fileId: transFileId,
          txnSetId: '0',
          m_errType: ' ',
        },
      }),
    }),

    getParentFCHSummary: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getParentFCHSummary`,
        params: {
          fileId: transFileId,
          txnSetId: '0',
        },
      }),
    }),

    getParentFCHSummaryDetails: builder.query({
      query: (pData) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getParentFCHSummaryDetails`,
        params: {
          fileId: pData.transFileId,
          m_fieldCode: pData.m_fieldCode,
        },
      }),
    }),

    getCustCorrect: builder.query({
      query: (pData) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getCustCorrect`,
        params: {
          fileId: pData.transFileId,
          txnSetId: '0',
          m_errType: pData.m_errType,
        },
      }),
    }),

    getEligibility: builder.query({
      query: (pData) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getEligibility`,
        params: {
          fileId: pData.transFileId,
          txnSetId: '0',

          m_errType: pData.m_errType,
        },
      }),
    }),
    getAllTrans: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getAllTrans`,
        params: {
          fileId: transFileId,
        },
      }),
    }),
    getMemberOMParent: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getMemberOMParent`,
        params: {
          fileId: transFileId,
        },
      }),
    }),
    gettermssubmitted: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/termssubmitted`,
        params: {
          fileId: transFileId,
        },
      }),
    }),
    getProcStats: builder.query({
      query: (transFileId) => ({
        method: 'POST',
        url: `/newPrimeParentReport/getProcStats`,
        params: {
          fileId: transFileId,
          txnSetId: '0',
        },
      }),
    }),
  }),
});

export const {
  useLazyGetAllParentFilesQuery,
  useGetAllParentFilesQuery,
  useGetchildFilesQuery,
  useGetFileDetailsQuery,
  useGetAddTransQuery,
  useGetChangeTransQuery,
  useGetnochangetransQuery,

  useGetParentFCHSummaryQuery,

  useGetExternalERWSummaryQuery,
  useGetInternalERWSummaryQuery,
  useGetProcStatsQuery,
  useGetEligibilityQuery,
  useGetCustCorrectQuery,
  useGetParentFCHSummaryDetailsQuery,
  useGetMemberOMParentQuery,
  useGetAllTransQuery,
  useGettermssubmittedQuery,
} = parentService;
