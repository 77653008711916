import React, { useEffect } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Flex } from '@abyss/web/ui/Flex';
import { Link } from '@abyss/web/ui/Link';
import { Button } from '@abyss/web/ui/Button';
import { useGettermssubmittedQuery } from '@src/store/services/ParentServices';
import { getDetailLink } from '@src/utils';
import { useGetChildTermsQuery } from '@src/store/services/ChildServices';
import { LoadingScreen } from '@src/common/LoadingScreen';

export const TermSubmitted = ({ fileId, isChild, setId }) => {
  //const { data: termSubmittedList } = useGettermssubmittedQuery(fileId);
  const { data: termSubmittedList, isLoading } = isChild
    ? useGetChildTermsQuery(setId)
    : useGettermssubmittedQuery(fileId);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Severity',
        accessor: 'severityCode',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        minWidth: 110,
        width: 110,
      },
      {
        Header: 'Policy Number',
        accessor: 'polNum',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Subscriber ID',
        accessor: 'subscrId',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 120,
        width: 120,
      },
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 120,
        width: 120,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Rel',
        accessor: 'relationship',
        minWidth: 75,
        width: 75,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'DOB',
        accessor: 'dob',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 95,
        width: 95,
      },
      {
        Header: 'Type',
        accessor: 'type',
        minWidth: 100,
        width: 100,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Status',
        accessor: 'stsCode',
        minWidth: 100,
        width: 100,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Error Name',
        accessor: 'warningName',
        minWidth: 100,
        width: 100,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Customer Data',
        accessor: 'custData',
        minWidth: 110,
        width: 110,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Source Data',
        accessor: 'sourceData',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 90,
        width: 90,
      },
      {
        Header: 'Coverage Type',
        accessor: 'covType',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 110,
        width: 110,
      },
      {
        Header: 'Coverage Start Date',
        accessor: 'covStrtDt',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 110,
        width: 110,
      },
      {
        Header: 'Coverage Plan Code',
        accessor: 'covPlnCd',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 110,
        width: 110,
      },
      {
        Header: 'Coverage End Date',
        accessor: 'covEndDt',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 110,
        width: 110,
      },
      {
        Header: 'Detail View',
        accessor: 'detailView',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        label: 'Customer Name',
        width: 80,
        minWidth: 80,
        Cell: ({ row }) => {
          return (
            <Flex
              css={{
                gap: '10px',
              }}
            >
              <Link
                size="10px"
                href={getDetailLink({ data: row?.original, isChild })}
              >
                <Button
                  variant="outline"
                  size="$sm"
                  css={{
                    fontSize: '12px',
                  }}
                >
                  <strong>Detail View</strong>
                </Button>
              </Link>
            </Flex>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (termSubmittedList) {
      dataTableProps.setData(termSubmittedList);
    }
  }, [termSubmittedList]);

  const dataTableProps = useDataTable({
    initialData: [],
    initialColumns: columns,
    uniqueStorageId: 'data-table-usage',
    showPagination: true,
    pageSizeOptions: [10, 25, 50, 100, 500],
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: true,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: true,
  });

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <DataTable hideTitleHeader tableState={dataTableProps} />
  );
};
