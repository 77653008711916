import React, { useState, useEffect } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Flex } from '@abyss/web/ui/Flex';
import { Link } from '@abyss/web/ui/Link';
import { Button } from '@abyss/web/ui/Button';

import { useGetParentFCHSummaryDetailsQuery } from '../../../store/services/ParentServices';
import { useGetFCHSummaryDetailsQuery } from '@src/store/services/ChildServices';
import { Card } from '@abyss/web/ui/Card';
import { getDetailLink } from '@src/utils';
import { LoadingScreen } from '@src/common/LoadingScreen';

export const FieldChangeSummaryChild = ({
  fileId,
  fieldCode,
  isChild,
  setId,
}) => {
  const { data: fileFCHDetailsList, isLoading } = isChild
    ? useGetFCHSummaryDetailsQuery({ txnSetId: setId, m_fieldCode: fieldCode })
    : useGetParentFCHSummaryDetailsQuery({
        transFileId: fileId,
        m_fieldCode: fieldCode,
      });

  const columns = React.useMemo(
    () => [
      {
        Header: 'Severity',
        accessor: 'severityCode',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 90,
        width: 90,
      },

      {
        Header: 'Customer Name',
        accessor: 'customerName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Policy Number',
        accessor: 'polNum',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Subscriber ID',
        accessor: 'subscrId',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 120,
        width: 120,
      },
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 120,
        width: 120,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Rel',
        accessor: 'relationship',
        minWidth: 75,
        width: 75,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Status',
        accessor: 'stsCode',
        minWidth: 100,
        width: 100,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Type',
        accessor: 'type',
        minWidth: 100,
        width: 100,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },

      {
        Header: 'Error Name',
        accessor: 'warningName',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Data Field',
        accessor: 'dataField',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Data Prior To Processing',
        accessor: 'sourceCol',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Data After Processing',
        accessor: 'buildCol',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Detail View',
        accessor: 'detailView',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 90,
        width: 90,
        Cell: ({ row }) => {
          return (
            <Flex
              css={{
                gap: '10px',
              }}
            >
              <Link
                size="10px"
                href={getDetailLink({ data: row?.original, isChild })}
              >
                <Button
                  variant="outline"
                  size="$sm"
                  css={{
                    fontSize: '12px',
                  }}
                >
                  <strong>Detail View</strong>
                </Button>
              </Link>
            </Flex>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (fileFCHDetailsList) {
      dataTableProps.setData(fileFCHDetailsList);
      console.log('field change detail list', fileFCHDetailsList);
    }
  }, [fileFCHDetailsList]);

  const dataTableProps = useDataTable({
    initialData: [],
    initialColumns: columns,
    uniqueStorageId: 'data-table-usage',
    showPagination: true,
    pageSizeOptions: [10, 25, 50, 100, 500],
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: true,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: true,
  });

  return (
    <>
      <Card header="">
        <Card.Section>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <DataTable hideTitleHeader tableState={dataTableProps} />
          )}
        </Card.Section>
      </Card>
    </>
  );
};
